import { useEffect, useState } from "react";

const useFetch = (url: string) => {
  const [content, setContent] = useState({}  as { data: any });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      
      try {
        const res = await fetch(url);
        const json = await res.json();

        setContent(json);
        setLoading(false);
      } catch {
        setContent({} as any);
        setLoading(false);
      }
    }

    fetchData();
  }, [url])

  return { loading, content }
}

export default useFetch;