import React, { Component, useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout";
import SEO from "../components/seo";
import GatsbyImage from "gatsby-image";
import DPADNewsBanner from "../components/DPADNewsBanner";
import { Document, Page } from 'react-pdf';
import useFetch from "../components/hooks/useFetch";
import * as appConstants from '../components/app-constants';


export default ({ pageContext: { dpadNew } }) => {
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (numPages) => {
        setPageNumber(numPages)
    }

    const pdf = (
        <>
        <a href={dpadNew?.attributes?.Media_Release_PDF?.data?.attributes.url} download>For better/optimal viewing click here.</a>
        <Document
            file={dpadNew?.attributes?.Media_Release_PDF?.data?.attributes.url}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf-setup"
        >
            <Page pageNumber={1} className="pdf-shadow" />
        </Document>
        </>
    );

    return (
        <Layout headerElement={<DPADNewsBanner title={dpadNew?.attributes?.News_Title} />}>
            <SEO title={dpadNew?.attributes?.News_Title} />
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <section id="page-content" className="sidebar-right">
                            <div className="container">
                                <div className="row">
                                    <div className="content col-lg-12">
                                        <div id="blog" className="single-post">
                                            <div className="post-item">
                                                <div className="post-item-wrap">
                                                    {/* <div className="post-image">
                                                        <a href="#">
                                                            <GatsbyImage fluid={news.acf.image.localFile.childImageSharp.fluid} />
                                                        </a>
                                                    </div> */}
                                                    <div className="post-item-description">
                                                        <h2>{dpadNew?.attributes?.News_Title}</h2>
                                                        <div style={{marginTop: -24, marginBottom: 50}}>
                                                            <a className="btn btn-xs btn-slide btn-facebook" href="https://www.facebook.com/African-Nova-Scotian-Decade-for-People-of-African-Descent-Coalition-103200967849187/">
                                                                <i className="fa fa-facebook-f"></i>
                                                                <span>Facebook</span>
                                                            </a>
                                                            <a className="btn btn-xs btn-slide btn-twitter" href="https://twitter.com/ansdpad" data-width="100">
                                                                <i className="fa fa-twitter"></i>
                                                                <span>Twitter</span>
                                                            </a>
                                                            <a className="btn btn-xs btn-slide btn-instagram" href="https://instagram.com/ansdpad" data-width="118">
                                                                <i className="fa fa-instagram"></i>
                                                                <span>Instagram</span>
                                                            </a>
                                                            <a className="btn btn-xs btn-slide btn-linkedin" href="https://www.linkedin.com/company/ansdpad" data-width="118">
                                                                <i className="fa fa-linkedin"></i>
                                                                <span>Linkedin</span>
                                                            </a>
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{__html: dpadNew?.attributes?.DPAD_News_Full_Description }}></div>
                                                        {dpadNew?.attributes?.Media_Release_PDF?.data?.attributes?.url === null ? '' : pdf}
                                                    </div>
                                                    {/* {news.acf.slug === 'OFFICAL_PETITON' && signPetition} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
)};

